import React, { Component } from "react"
import { graphql } from 'gatsby'
import GlobalBanner from '../components/globalBanner'
import Layout from '../components/layout'
import { hasCookie } from '../services/cookies';

class ProjectBrief extends Component {
  render() {

    const data = this.props.data;

    if (hasCookie() == true) {
      return (
        <Layout>
          <div className="project-brief-template">
            <GlobalBanner pageTitle={data.wpPage.title}
                          bgSharpImg={data.wpPage.featuredImage !== null && data.wpPage.featuredImage.node.localFile !== null && data.wpPage.featuredImage.node.localFile.childImageSharp !== null && data.wpPage.featuredImage.node.localFile}
                          bgImg={data.wpPage.featuredImage.node.source_url}
            />
            <div className="uk-cover-container ctn-main push-up-small uk-card uk-card-default">
              <div id="loading" className="centered uk-margin-large">Loading... <span className="uk-margin-small-right" data-uk-spinner="ratio: 1"></span></div>
              <iframe title="Project Brief form" className="uk-hidden js-project-brief-iframe" src={`${process.env.PROJECTBRIEF_PATH}`} width="100%" height="1200px" frameborder="0" ></iframe>
            </div>
          </div> {/* End .project-brief-template */}
        </Layout>
      );
    } else {
      return (
        <></>
      )
    }
  }
}

export default ProjectBrief;

export const pageQuery = graphql`
  query {
    wpPage(slug: {eq: "project-brief"}) {
      id
      title
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                tracedSVGOptions: {background: "#1e87f0", color: "#004f99"}
                sizes: "(min-width: 1429px) 1920px, 100vw"
              )
            }
          }
          sourceUrl
        }
      }
    }
  }
`
